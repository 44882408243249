import React, { useEffect, useState } from 'react';
import axios from 'axios';
import MyTable from "../toolPanel";

function GetFullTime(props) {
    const [data, setData] = useState(null);
    const { category, location, sort, query } = props;
    const encodedKeyword = encodeURIComponent(query);

    useEffect(() => {
        // URL, куда будет выполнен GET-запрос
        const apiUrl = `https://www.avito.ru/web/1/main/items?locationId=${location}&categoryId=${category}&limit=${sort}&query=${encodedKeyword}`;

        // Выполняем GET-запрос с помощью Axios
        axios.get(apiUrl)
            .then(response => {
                // Обработка успешного ответа от сервера
                setData(response.data);
            })
            .catch(error => {
                // Обработка ошибок
                console.error('Произошла ошибка:', error);
            });
    }, [category, location, sort, encodedKeyword]);

    return (
        <div>
            {/*<h1>Пример GET-запроса с Axios</h1>*/}
            {data ? (
                <React.Fragment>
                    <MyTable data={data.items}/>
                    <div>
                        {/*<pre>{JSON.stringify(data, null, 2)}</pre>*/}
                    </div>
                </React.Fragment>
            ) : (
                <p>Загрузка данных...</p>
            )}
        </div>
    );
}

export default GetFullTime;