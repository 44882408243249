import './App.css';
import MyTable from "./components/toolPanel";
import GetFullTime from "./components/GetFullTime";
import React, {useState} from "react";

const categoryList = [
    {id: 9, name: "Aвтомобили"},
    {id: 10, name: "Запчасти и аксессуары"},
    {id: 11, name: "Водный транспорт"},
    {id: 14, name: "Мотоциклы и мототехника"},
    {id: 19, name: "Ремонт и строительство"},
    {id: 20, name: "Мебель и интерьер"},
    {id: 21, name: "Бытовая техника"},
    {id: 23, name: "Комнаты"},
    {id: 24, name: "Квартиры"},
    {id: 25, name: "Дома, дачи, коттеджи"},
    {id: 26, name: "Земельные участки"},
    {id: 27, name: "Одежда, обувь, аксессуары"},
    {id: 28, name: "Часы и украшения"},
    {id: 29, name: "Детская одежда и обувь"},
    {id: 30, name: "Товары для детей и игрушки"},
    {id: 31, name: "Настольные компьютеры"},
    {id: 32, name: "Аудио и видео"},
    {id: 33, name: "Билеты и путешествия"},
    {id: 34, name: "Велосипеды"},
    {id: 36, name: "Коллекционирование"},
    {id: 38, name: "Музыкальные инструменты"},
    {id: 39, name: "Спорт и отдых"},
    {id: 40, name: "Оборудование для бизнеса"},
    {id: 42, name: "Коммерческая недвижимость"},
    {id: 81, name: "Грузовики и спецтехника"},
    {id: 82, name: "Продукты питания"},
    {id: 83, name: "Книги и журналы"},
    {id: 84, name: "Телефоны"},
    {id: 85, name: "Гаражи и машиноместа"},
    {id: 86, name: "Недвижимость за рубежом"},
    {id: 87, name: "Посуда и товары для кухни"},
    {id: 88, name: "Красота и здоровье"},
    {id: 89, name: "Собаки"},
    {id: 90, name: "Кошки"},
    {id: 91, name: "Птицы"},
    {id: 92, name: "Аквариум"},
    {id: 93, name: "Другие животные"},
    {id: 94, name: "Товары для животных"},
    {id: 96, name: "Планшеты и электронные книги"},
    {id: 97, name: "Игры, приставки и программы"},
    {id: 98, name: "Ноутбуки"},
    {id: 99, name: "Оргтехника и расходники"},
    {id: 101, name: "Товары для компьютера"},
    {id: 102, name: "Охота и рыбалка"},
    {id: 105, name: "Фототехника"},
    {id: 106, name: "Растения"},
    {id: 111, name: "Вакансии"},
    {id: 112, name: "Резюме"},
    {id: 114, name: "Предложения услуг"},
    {id: 115, name: "Запросы на услуги"},
    {id: 116, name: "Готовый бизнес"}
]

const locationList = [
    { id: 645530, name: "Адыгея" },
    { id: 621590, name: "Алтайский край" },
    { id: 622470, name: "Амурская область" },
    { id: 622650, name: "Архангельская область" },
    { id: 623110, name: "Астраханская область" },
    { id: 645790, name: "Башкортостан" },
    { id: 623410, name: "Белгородская область" },
    { id: 623840, name: "Брянская область" },
    { id: 623845, name: "Бурятия" },
    { id: 624300, name: "Владимирская область" },
    { id: 624770, name: "Волгоградская область" },
    { id: 625330, name: "Вологодская область" },
    { id: 625670, name: "Воронежская область" },
    { id: 646710, name: "Дагестан" },
    { id: 626470, name: "Еврейская АО" },
    { id: 661460, name: "Забайкальский край" },
    { id: 628450, name: "Ивановская область" },
    { id: 628455, name: "Ингушетия" },
    { id: 628780, name: "Иркутская область" },
    { id: 629430, name: "Кабардино-Балкария" },
    { id: 629990, name: "Калининградская область" },
    { id: 629995, name: "Калмыкия" },
    { id: 630270, name: "Калужская область" },
    { id: 630660, name: "Камчатский край" },
    { id: 630750, name: "Карачаево-Черкесия" },
    { id: 648070, name: "Карелия" },
    { id: 631080, name: "Кемеровская область" },
    { id: 631730, name: "Кировская область" },
    { id: 648340, name: "Коми" },
    { id: 632390, name: "Костромская область" },
    { id: 632660, name: "Краснодарский край" },
    { id: 634930, name: "Красноярский край" },
    { id: 621550, name: "Крым" },
    { id: 635730, name: "Курганская область" },
    { id: 636030, name: "Курская область" },
    { id: 636370, name: "Ленинградская область" },
    { id: 637260, name: "Липецкая область" },
    { id: 637530, name: "Магаданская область" },
    { id: 648730, name: "Марий Эл" },
    { id: 648960, name: "Мордовия" },
    { id: 637640, name: "Москва" },
    { id: 637680, name: "Московская область" },
    { id: 640000, name: "Мурманская область" },
    { id: 640001, name: "Ненецкий АО" },
    { id: 640310, name: "Нижегородская область" },
    { id: 641240, name: "Новгородская область" },
    { id: 641470, name: "Новосибирская область" },
    { id: 642020, name: "Омская область" },
    { id: 642480, name: "Оренбургская область" },
    { id: 643030, name: "Орловская область" },
    { id: 643250, name: "Пензенская область" },
    { id: 643700, name: "Пермский край" },
    { id: 644490, name: "Приморский край" },
    { id: 645260, name: "Псковская область" },
    { id: 662811, name: "Республика Алтай" },
    { id: 651110, name: "Ростовская область" },
    { id: 652220, name: "Рязанская область" },
    { id: 652560, name: "Самарская область" },
    { id: 653240, name: "Санкт-Петербург" },
    { id: 653420, name: "Саратовская область" },
    { id: 649330, name: "Саха (Якутия)" },
    { id: 653430, name: "Сахалинская область" },
    { id: 653700, name: "Свердловская область" },
    { id: 649820, name: "Северная Осетия" },
    { id: 654860, name: "Смоленская область" },
    { id: 655190, name: "Ставропольский край" },
    { id: 656520, name: "Тамбовская область" },
    { id: 650130, name: "Татарстан" },
    { id: 656890, name: "Тверская область" },
    { id: 657310, name: "Томская область" },
    { id: 657610, name: "Тульская область" },
    { id: 650690, name: "Тыва" },
    { id: 658170, name: "Тюменская область" },
    { id: 659200, name: "Удмуртия" },
    { id: 659540, name: "Ульяновская область" },
    { id: 659930, name: "Хабаровский край" },
    { id: 650890, name: "Хакасия" },
    { id: 660300, name: "Ханты-Мансийский АО" },
    { id: 660710, name: "Челябинская область" },
    { id: 660711, name: "Чеченская республика" },
    { id: 662000, name: "Чувашия" },
    { id: 662280, name: "Чукотский АО" },
    { id: 662330, name: "Ямало-Ненецкий АО" },
    { id: 662530, name: "Ярославская область" },
]
function App() {

    const [selectedCategory, setSelectedCategory] = useState({ id: '', name: '' });
    const [selectedLocation, setSelectedLocation] = useState({ id: '', name: '' });
    const [sort, setSort] = useState(0);
    const [value, setValue] = useState("");
    const handleCategoryChange = (e) => {
        let selectedId = parseFloat(e.target.value);
        let selectedCategoryObject = categoryList.find(category => category.id === selectedId);
        console.log('category', selectedCategoryObject)
        setSelectedCategory(selectedCategoryObject);
    };

    const handleLocationChange = (e) => {
        let selectedId = parseFloat(e.target.value);
        let selectedLocationObject = locationList.find(location => location.id === selectedId);
        console.log('location', selectedLocationObject)
        setSelectedLocation(selectedLocationObject);
    };

    const handleSortChange = (e) => {
        setSort(parseFloat(e.target.value));
    }

    const handleValueChange = (e) => {
        setValue(e.target.value);
    }

    return (
    <div className="App">
        <div>
            <select
                value={selectedCategory?.id}
                onChange={(event) => handleCategoryChange(event)}
            >
                <option value="" disabled>Выберите категорию</option>
                {categoryList.map(category => (
                    <option key={category.id} value={category.id}>
                        {category.name}
                    </option>
                ))}
            </select>

            <select
                value={selectedLocation?.id}
                onChange={(event) => handleLocationChange(event)}
            >
                <option value="" disabled>Выберите регион</option>
                {locationList.map(location => (
                    <option key={location.id} value={location.id}>
                        {location.name}
                    </option>
                ))}
            </select>

            <input
                type="text"
                value={sort}
                onChange={(event) => handleSortChange(event)}
                placeholder="Введите количество объявлений"
            />

            <input
                type="text"
                value={value}
                onChange={(event) => handleValueChange(event)}
                placeholder="Введите ключевое слово"
            />

        </div>

      <GetFullTime category={selectedCategory?.id} location={selectedLocation?.id} sort={sort} query={value}/>
    </div>
  );
}

export default App;
