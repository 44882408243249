import React, { useState } from 'react';
import axios from 'axios';

function MyTable(props) {

    const { data } = props;

    const handleButtonClick3 = () => {
        // Обработчик для Кнопки 3
        console.log('Кнопка 3 нажата');
    }

    const renderImage = (image) => {
        if(!image)
            return;

        for (let key in image) {
            if (image.hasOwnProperty(key)) {
                return (
                    <React.Fragment>
                        <a target="_blank" href={image[key]}>{key}</a>
                        <br/>
                    </React.Fragment>
                )
            }
        }
    }

    const renderItems = (item, index) => {
        if(item?.id === 0)
            return;

        return (
            <tr key={item?.id}>
                <td>{index+1}</td>
                <td>{item?.id}</td>
                <td>{item?.category?.id}</td>
                <td>{item?.title}</td>
                <td>{item?.location?.name}</td>
                <td>{item.images && (item?.images.map(image => renderImage(image)))}</td>
                {/*<td>{item?.col5}</td>*/}
            </tr>
        )
    }

    return (
        <div>

           {/* <button onClick={handleButtonClick1}>Кнопка 1</button>*/}
          {/*  <button onClick={handleButtonClick2}>Кнопка 2</button>*/}
       {/*     <button onClick={handleButtonClick3}>Кнопка 3</button>*/}
            <h2>Данные с сервера:</h2>
            <table className="my-table">
                <thead>
                <tr>
                    <th>ID объявления</th>
                    <th>ID категории</th>
                    <th>Название объявления</th>
                    <th>Локализация</th>
                    <th>Изображения</th>
                </tr>
                </thead>
                {data && (
                    <tbody>
                        {data.map((item, index) => renderItems(item, index))}
                    </tbody>
                )}
            </table>
        </div>
    );
}

export default MyTable;